import React, { useState, useContext } from "react";
import { withTheme } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Container } from "reactstrap";
import {ApiContext} from "../App";

const AddMessageDialog = ({onClick, config}) => {
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [insertError, setInsertError] = useState(false);
  const { api } = useContext(ApiContext);

  const [values, setValues] = useState({
    text: '',
    field2: '',
    field3: '',
    hot: 0
  });

  const insertQuestion = () => {
    if (values.text !== null && values.text !== '') {
      setMessageDialogOpen(false);
      setInsertError(false);
      api.connect().then(_ => {
        console.log("DO SAVE EVENT!", values);
        api.insertQuestion(values, config);
        resetFields();
      });
    } else {
      setInsertError(true);
    }
  };

  const openMessageDialog = () => {
    setMessageDialogOpen(true);
    onClick();
  };

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const resetFields = () => {
    setValues({
      text: '',
      field2: '',
      field3: '',
      hot: 0
    });
  };

  const handleClose = () => {
    setMessageDialogOpen(false);
    resetFields();
    setInsertError(false);
  };

  return (
    <span>
      <span
        onClick={openMessageDialog}
      >
        <IconButton variant="contained" color="default">
          <AddBoxIcon />
        </IconButton> Add Question
      </span>

      <Dialog
        open={messageDialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Question</DialogTitle>
        <DialogContent>
          <Container className="">
            <div className="wrapper">
              <div className={"middleSection"}>
                <article className="main">
                  <TextField
                    fullWidth
                    id={`text`}
                    label="Question"
                    multiline
                    rowsMax="4"
                    value={values.text}
                    className=""
                    margin="normal"
                    variant="filled"
                    onChange={handleChange("text")}
                    error={insertError}
                    helperText={insertError ? "Please enter a question." : ""}
                  />
                </article>
                <div className={"middleSectionBottom"}>
                  <div className="extrafield field2">
                    <TextField
                      id={`field2`}
                      label="Note for presenter"
                      className=""
                      value={values.field2}
                      margin="normal"
                      variant="filled"
                      onChange={handleChange("field2")}
                    />
                  </div>
                  <div className="extrafield field3">
                    <TextField
                      id={`field3`}
                      label="Note for admin"
                      className=""
                      value={values.field3}
                      margin="normal"
                      variant="filled"
                      onChange={handleChange("field3")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button onClick={insertQuestion} color="default">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};
export default withTheme(AddMessageDialog);
