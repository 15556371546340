import React, {useState, useContext, useEffect, useRef} from "react";
import {Row, Col} from "reactstrap";
import {withTheme} from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InvertColorsIcon from "@material-ui/icons/InvertColorsRounded";
import ExportIcon from "@material-ui/icons/CloudDownload";
import SettingsIcon from "@material-ui/icons/Settings";
import moment from "moment";
import countdown from "moment-countdown";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {makeStyles} from "@material-ui/styles";
import ResetEventDialog from './ResetEventDialog';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddMessageDialog from "./AddMessageDialog";
import Countdown from "./Countdown";
import {ApiContext} from "../App";
import Play from "./Play";
import Pause from "./Pause";

const useStyles = makeStyles(theme => ({
  headerCol: {
    alignItems: "center",
    verticalAlign: "middle",
    display: "inline-grid",
  },
  playPauseCol: {
    alignItems: "center",
    verticalAlign: "middle",
    display: "inline-grid",
    width: '60px',
    position: 'absolute',
    left: '-40px',
    top: '20px'
  },
  secondHeader: {
    paddingLeft: '24px',
    paddingRight: '24px'
  }
}));

const AdminHeader = ({
                       newMessageCount,
                       onThemeChange,
                       filter,
                       onFilterChange,
                       newQuestionsCount,
                       allQuestionsCount,
                       reversed,
                       lastPoll,
                       handlePollingClick,
                       lastReceived,
                       setNewMessages,
                       shouldUpdateLastRecieved
                     }) => {
  const [showDefaultMessage, setShowDefaultMessage] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastPollFormatted, setLastPollFormatted] = useState('...');
  const {api, isPolling, config} = useContext(ApiContext);
  const [lastReceivedFormatted, setLastReceivedFormatted] = useState('...');

  const classes = useStyles();

  const [colour, setColour] = useState('');
  const [pollText, setPollText] = useState('...');

  const pollingTextInterval = useRef(0);

  useEffect(() => {
    clearInterval(pollingTextInterval.current);
    pollingTextInterval.current = setInterval(() => {
      let c = 'defaultPoll';
      let newText = '...';
      if (lastPoll) {
        let diff = moment().diff(moment(lastPoll));
        if ((diff / 1000) > 120) {
          c = 'dangerPoll';
        } else if ((diff / 1000) > 60) {
          c = 'warningPoll';
        }
        newText = moment(lastPoll).countdown(moment(), countdown.MINUTES|countdown.SECONDS).toString() + ' ago';
      }

      if (colour !== c || newText !== pollText) {
        setColour(c);
        setPollText(newText);
        setLastPollFormatted(() => {
          return (<Typography className={c} variant="subtitle2">
            {newText}
          </Typography>)
        });
        setLastReceivedFormatted(() => {
          return lastReceived ? moment(lastReceived).countdown(moment(), countdown.MINUTES|countdown.SECONDS).toString() + ' ago' : "...";
        });
      }
    }, 1000);
  }, [lastPoll, lastReceived, pollText, setPollText, colour, setColour, setLastPollFormatted]);

  const handleFilter = (event, newFilter) => {
    onFilterChange(newFilter, reversed);
  };

  const handleDefaultMessage = event => {
    let toggleValue = event.target.checked ? 1 : 0;
    api.connect().then(_ => {
      api.toggleDefaultMessage(toggleValue);
      setShowDefaultMessage(toggleValue ? true : false);
    });
  };

  const handleSwitchChange = name => event => {
    onFilterChange(filter, event.target.checked);
  };

  const exportData = () => {
    api.connect().then(_ => {
      api.exportData();
    });
  };

  //if (config) return <ErrorView configNotFound={true}/>;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';

  useEffect(() => {
    moment.relativeTimeThreshold("s", 61);
    moment.relativeTimeThreshold("m", 61);
  }, [])

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={menuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={onThemeChange}>
        <IconButton aria-label="InvertColors">
          <InvertColorsIcon/>
        </IconButton> Theme
      </MenuItem>
      <MenuItem><AddMessageDialog onClick={handleMenuClose}
                                  config={config}/></MenuItem>
      <MenuItem onClick={exportData}>
        <IconButton variant="contained" color="primary">
          <ExportIcon/>
        </IconButton> Report
      </MenuItem>
      <MenuItem><ResetEventDialog
        onClick={handleMenuClose}
        setNewMessages={setNewMessages}
        shouldUpdateLastRecieved={shouldUpdateLastRecieved}
      /></MenuItem>
    </Menu>
  );

  return (
    <AppBar
      position="static"
      color="default"
      style={{marginBottom: "15px"}}
    >
      <Toolbar>
        <Row style={{width: "100%"}}>
          <Col className={classes.headerCol}>
            <Row>
              <Col className={classes.headerCol}>
                <Typography variant="h5" color="inherit" align="left">
                  {config.eventName}
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col className={classes.headerCol}>
            <Row>
              <Col>
                <Typography color="inherit" variant="h6">
                  Last received
                </Typography>
                <Typography color="inherit" variant="subtitle2">
                  {lastReceivedFormatted}
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col className={classes.headerCol}>
            <Row>
              <Col>
                <Typography color="inherit" variant="h6">
                  Server polled
                </Typography>
                {lastPollFormatted}
                <Typography color="inherit" variant="subtitle2">
                  {newMessageCount} messages waiting.
                </Typography>
              </Col>
              <div className={classes.playPauseCol}>
                {isPolling
                  ? <Pause onPollingClick={handlePollingClick}/>
                  : <Play onPollingClick={handlePollingClick}/>
                }
              </div>
            </Row>
          </Col>
          <Col className={classes.headerCol}>
            <Countdown showControls={true}/>
          </Col>
          <Col xs="auto">
            <IconButton
              edge="end"
              aria-label="Settings"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <SettingsIcon/>
            </IconButton>
          </Col>
        </Row>
      </Toolbar>
      {renderMenu}
      <Row className={classes.secondHeader}>
        <Col>
          <FormControlLabel
            control={
              <Switch
                checked={reversed}
                onChange={handleSwitchChange("reverse")}
                value="reverse"
              />
            }
            label="Reverse"
          />
        </Col>
        <Col>
          <FormControlLabel
            control={
              <Switch
                checked={showDefaultMessage}
                onChange={handleDefaultMessage}
                value="showDefaultMessage"
              />
            }
            label="Show Default Message"
          />
        </Col>
        <Col>
          <RadioGroup
            aria-label="Filter"
            name="filter"
            value={filter}
            onChange={handleFilter}
            row
          >
            <FormLabel
              component="label"
              style={{
                marginRight: "16px",
                verticalAlign: "middle",
                alignItems: "center",
                display: "inline-flex"
              }}
            >
              <span>Filter:</span>
            </FormLabel>
            <FormControlLabel
              value="all"
              control={<Radio/>}
              label={`All (${allQuestionsCount})`}
            />
            <FormControlLabel
              value="new"
              control={<Radio/>}
              label={`New (${newQuestionsCount})`}
            />
          </RadioGroup>
        </Col>
      </Row>
    </AppBar>
  );
};
export default withTheme(AdminHeader);
