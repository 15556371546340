import React, { useEffect, useRef } from "react";
import AdminQuestion from "./AdminQuestion";
import {
  List,
  WindowScroller,
  CellMeasurer,
  CellMeasurerCache,
  AutoSizer
} from "react-virtualized";

const AdminQuestionList = ({ updateQuestion, messages }) => {
  {
    const listRef = useRef(null);

    const _cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 190
    });

    useEffect(() => {
      listRef.current.forceUpdate();
      listRef.current.recomputeRowHeights();
    }, [messages]);

    const rowRenderer = ({
      key, // Unique key within array of rows
      index, // Index of row within collection
      isScrolling, // The List is currently being scrolled
      isVisible, // This row is visible within the List (eg it is not an overscanned row)
      style, // Style object to be applied to row (to position it)
      parent
    }) => {
      let item = messages[index];
      return (
        <CellMeasurer
          cache={_cache}
          columnIndex={0}
          key={key}
          rowIndex={index}
          parent={parent}
        >
          {({ measure }) => (
            <div
              onLoad={measure}
              key={item.id}
              style={{ ...style, paddingBottom: "20px" }}
            >
              <AdminQuestion updateQ={updateQuestion} key={key} index={index} question={item} />
            </div>
          )}
        </CellMeasurer>
      );
    };

    return (
        <WindowScroller>
          {({ height, isScrolling, onChildScroll, scrollTop }) => (
            <AutoSizer disableHeight>
               {({ width }) => (
                <List
                  autoHeight
                  width={width-15}
                  height={220*(messages.length+1)}
                  isScrolling={isScrolling}
                  rowCount={messages.length}
                  rowHeight={_cache.rowHeight}
                  rowRenderer={rowRenderer}
                  overscanRowCount={10}
                  ref={listRef}
                  onScroll={onChildScroll}
                  scrollTop={scrollTop}
                />
                 )}
            </AutoSizer>
          )}
        </WindowScroller>
    );
  }
};
export default AdminQuestionList;
