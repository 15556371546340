import React, {useState, useContext} from "react";
import {withTheme} from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import {ApiContext} from "../App";

const ResetEventDialog = ({
                            onClick,
                            setNewMessages,
                            shouldUpdateLastRecieved
                          }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [resetConfirmationText, setResetConfirmationText] = useState("");
  const [resetError, setResetError] = useState(false);
  const {api} = useContext(ApiContext);

  const resetEvent = () => {
    if (resetConfirmationText === "reset") {
      setDeleteDialogOpen(false);
      setResetConfirmationText("");
      setResetError(false);
      api.connect().then(_ => {
        api.resetEvent();
        shouldUpdateLastRecieved.current = false;
        setNewMessages([]);
      });
    } else {
      setResetError(true);
    }
  };

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
    onClick();
  };

  const handleClose = () => {
    setDeleteDialogOpen(false);
    setResetConfirmationText("");
    setResetError(false);
  };

  const handleResetTextChange = event => {
    setResetConfirmationText(event.target.value);
  };

  return (
    <span>
      <span
        onClick={openDeleteDialog}
      >
        <IconButton variant="contained" color="secondary">
          <DeleteIcon/>
        </IconButton> Reset
      </span>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reset Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Type 'reset' to confirm.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            fullWidth
            variant="filled"
            value={resetConfirmationText}
            onChange={handleResetTextChange}
            error={resetError}
            helperText={resetError ? "Type 'reset' to confirm." : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={resetEvent} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};
export default withTheme(ResetEventDialog);
