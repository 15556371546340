import React, {useState, useEffect, createContext} from "react";
import "./App.css";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import AdminView from "./components/AdminView";
import PresenterView from "./components/PresenterView";
import ProjectorView from "./components/ProjectorView";
import ErrorView from "./components/ErrorView";
import {MuiThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {createMuiTheme} from "@material-ui/core/styles";
import Api from "./api";
// import { fade } from "@material-ui/core/styles/colorManipulator";

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  //console.log('why you render?');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });

}

export const ApiContext = createContext({});
const a = new Api((error) => console.log(error));

const App = () => {

  const [api, setApi] = useState(a);
  const [config, setConfig] = useState({});
  const [readonly, setReadonly] = useState(false);
  const [isPolling, setIsPolling] = useState(true);

  const [theme, setTheme] = useState({
    palette: {
      type: localStorage.getItem('theme') || "dark",
      primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      }
    }
  });

  useEffect(() => {
    localStorage.setItem('theme', theme.palette.type);
  }, [theme.palette.type]);

  const toggleDarkTheme = () => {
    let newPaletteType = theme.palette.type === "light" ? "dark" : "light";
    setTheme({
      palette: {
        type: newPaletteType
      }
    });
  };

  const muiTheme = createMuiTheme(theme);
  // muiTheme.overrides = {
  //   MuiFilledInput: {
  //     root: {
  //       borderBottom: "1px solid #e2e2e1",
  //       overflow: "hidden",
  //       borderRadius: 4,
  //       transition: muiTheme.transitions.create(["border-color", "box-shadow"]),
  //       "&$focused": {
  //         boxShadow: `${fade("rgb(0, 255, 0)", 0.25)} 0 0 0 2px`,
  //         borderColor: "rgb(0, 255, 0)",
  //         color: "rgb(0, 255, 0)"
  //       }
  //     }
  //   },
  //   MuiInputLabel: {
  //     filled: {
  //       "&$focused": {
  //         color: "rgb(0, 255, 0)"
  //       }
  //     }
  //   }
  // };
  return (
    <MuiThemeProvider theme={muiTheme}>
      <ApiContext.Provider value={{
        api,
        setApi,
        readonly,
        setReadonly,
        config,
        setConfig,
        isPolling,
        setIsPolling
      }}>
        <CssBaseline/>
        <Router>
          <div className="App"
               style={{backgroundColor: theme.palette.type === "light" ? '#e5e5e5' : '#303030'}}>
            <Switch>
              <Route
                path='/:eventId/admin'
                render={(props) => {
                  api.setEventId(props.match.params.eventId);
                  return (<AdminView {...props}
                                     onThemeChange={toggleDarkTheme}/>);
                }
                }
              />
              <Route
                path='/:eventId/presenter'
                render={(props) => {
                  api.setEventId(props.match.params.eventId);
                  return (<PresenterView {...props}
                                         onThemeChange={toggleDarkTheme}/>);
                }
                }
              />
              <Route
                path='/:eventId/readonly'
                render={(props) => {
                  api.setEventId(props.match.params.eventId);
                  setReadonly(true);
                  return (<PresenterView {...props}
                                         onThemeChange={toggleDarkTheme}
                                         readonly/>);
                }
                }
              />
              <Route path="/:eventId/projector"
                 render={(props) => {
                   api.setEventId(props.match.params.eventId);
                   return (<ProjectorView {...props} />);
                 }
                 }
              />
              <Route component={ErrorView}/>
            </Switch>
          </div>
        </Router>
      </ApiContext.Provider>
    </MuiThemeProvider>
  );
};

export default App;
