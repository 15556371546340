import React from 'react'

const Play = ({onPollingClick}) => {
  return (
    <svg style={{fill:"white", cursor:"hand"}} viewBox="0 0 20 20" onClick={onPollingClick}>
      <polygon points="0,0 15,10 0,20" />
    </svg>
  )
}

export default Play
