import React, {
  useCallback,
  useContext,
  useEffect, useRef,
  useState
} from "react";
import AdminQuestionList from "./AdminQuestionList";
import AdminHeader from "./AdminHeader";
import {withTheme} from "@material-ui/styles";
import ReactPaginate from 'react-paginate';
// import Pagination from '@material-ui/lab/Pagination';
import moment from "moment";
import _ from "lodash";
import {ApiContext} from "../App";

function AdminView(props) {

  const {api, setConfig, isPolling, setIsPolling} = useContext(ApiContext);

  const [pollInterval, setPollInterval] = useState(0);
  const [lastPoll, setLastPoll] = useState(null);
  const [filter, setFilter] = useState('new');
  const [reversed, setReversed] = useState(false);
  const [allCount, setAllCount] = useState(0);
  const [newCount, setNewCount] = useState(0);
  const [messages, setMessages] = useState([]);
  const [newMessages, setNewMessages] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [messagesPerPage, setMessagePerPage] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [newMessageCount, setNewMessageCount] = useState(0);
  const [lastReceived, setLastReceived] = useState(null);

  const [filteredData, setFilteredData] = useState([]);

  const shouldUpdateLastRecieved = useRef(true);

  const initPolling = () => {
    setIsPolling(true);
  }

  const handlePollingClick = () => {
    if (isPolling === false) {
      // changing from false to true. grab messages instantly.
      shouldUpdateLastRecieved.current = false;
      loadFromCOSI();
    }
    setIsPolling(!isPolling);
  }

  const getConfig = () => {
    api.connect().then(_ => {
      api.getConfig((err, config) => {
        setConfig(config);
      });
    })
  }

  const loadFromCOSI = () => {
    api.connect().then(_ => {
      api.loadNewQuestionsFromCOSI();
    });
  }

  const adminMessagesCallback = useCallback((err, messages) => {
    console.log('setting admin messages from socket');
    console.log(messages);
    setNewMessages(messages);
  }, [setNewMessages])

  useEffect(() => {
    if (isPolling) {
      setLastPoll(moment().format());
      setMessages(newMessages);
      if (shouldUpdateLastRecieved.current === true && newMessages.length !== messages.length) {
        setLastReceived(moment());
      }
      shouldUpdateLastRecieved.current = true;
      setNewMessageCount(0);
    } else {
      if (newMessageCount !== (newMessages.length - messages.length)) {
        setLastReceived(moment());
      }
      if ((newMessages.length - messages.length) > 0) {
        setNewMessageCount(newMessages.length - messages.length);
      } else {
        setNewMessageCount(0);
      }
    }
  }, [newMessages]);

  const loadQuestions = () => {
    api.connect().then(_ => {
      api.getAdminMessages(adminMessagesCallback);
    });
  }

  const filterQuestions = (filter, reversed) => {
    setFilter(filter);
    setReversed(reversed);
  }

  const handlePageClick = (data) => {
    setPageNumber(data.selected + 1);
  };

  const paginate = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const updateQuestion = (question) => {
    let newMessages = [...messages];
    let i = messages.findIndex((item) => {
      return item.id === question.id;
    });

    newMessages[i] = {...newMessages[i], ...question};
    console.log('setting messages via update');
    console.log(newMessages);
    setMessages(newMessages);
  }

  useEffect(() => {
    if (messages.length === 0) {
      setFilteredData([]);
      return;
    }
    let newMessages = _.filter(messages, {
      hidden: 0,
      approved: null
    });

    setNewCount(newMessages.length);
    setAllCount(messages.length);

    if (filter === 'new') {
      let count = Math.ceil(newMessages.length / messagesPerPage)
      if (count === 0) {
        setPageCount(1);
      } else {
        setPageCount(count);
      }
      setFilteredData(paginate(_.orderBy(newMessages, ["created"], [reversed ? "asc" : "desc"]), messagesPerPage, pageNumber));
    } else {
      let count = Math.ceil(messages.length / messagesPerPage)
      if (count === 0) {
        setPageCount(1);
      } else {
        setPageCount(count);
      }
      setFilteredData(paginate(_.orderBy(messages, ["created"], [reversed ? "asc" : "desc"]), messagesPerPage, pageNumber));
    }
  }, [filter, reversed, messages, setFilteredData, setAllCount, setNewCount, messagesPerPage, setPageCount, pageNumber]);


  useEffect(() => {
    getConfig();
    setTimeout(getConfig, 5000);
    initPolling();
    loadQuestions();
    clearInterval(pollInterval);
    setPollInterval(setInterval(
      loadFromCOSI,
      15000
    ));
  }, []);

  return (
    <div className="centerContainer">
      <div id="app">
        <header>
          <AdminHeader
            newMessageCount={newMessageCount}
            filter={filter}
            onFilterChange={filterQuestions}
            newQuestionsCount={newCount}
            allQuestionsCount={allCount}
            reversed={reversed}
            onThemeChange={props.onThemeChange}
            lastPoll={lastPoll}
            handlePollingClick={handlePollingClick}
            lastReceived={lastReceived}
            setNewMessages={setNewMessages}
            shouldUpdateLastRecieved={shouldUpdateLastRecieved}
          />
        </header>
        <main>
          <AdminQuestionList updateQuestion={updateQuestion}
                             messages={filteredData}/>
        </main>
        <footer>
          {/* <Pagination count={10} color="primary" />*/}
          <div className="paginationcenter">

            <ReactPaginate
              previousLabel={"← Previous"}
              nextLabel={"Next →"}
              breakLabel={'...'}
              breakClassName={'pagination__link'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          </div>
        </footer>

      </div>
    </div>
  );
}


export default withTheme(AdminView);
