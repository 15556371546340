import React, {useState, useContext} from "react";
import {Row, Col} from "reactstrap";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DragHandleIcon from "@material-ui/icons/Reorder";
import {makeStyles} from "@material-ui/styles";
import {sortableHandle} from "react-sortable-hoc";

import CheckCircleIcon from "@material-ui/icons/AddBox";
import RemoveQuestionIcon from "@material-ui/icons/Cancel";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from "@material-ui/icons/Favorite";
import MoveTopIcon from "@material-ui/icons/ArrowDropUp";
import {ApiContext} from "../App";

const useStyles = makeStyles(theme => ({
  question: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(1, 0)
  },
  questionButtons: {
    marginLeft: 'auto',
    padding: theme.spacing(1, 1),
    margin: theme.spacing(0, 0)
  },
  checkIcon: {
    fontSize: "4rem",
    color: props => (props.clicked === true ?
        theme.palette.type === "dark" ? "#00e700" : "#00a500" :
        theme.palette.type === "dark" ? "#00b4ff" : "#00b4ff"
    )
  },
  faveIcon: {
    color: theme.palette.type === "dark" ? "#f93c3a" : "#aa0200",
    float: "left",
    fontSize: "2.5rem",
    position: "absolute",
    top: 0,
    margin: theme.spacing(0, 1, 1, 1.5)
  },
  faveIconReadOnly: {
    color: theme.palette.type === "dark" ? "#f93c3a" : "#aa0200",
    float: "left",
    fontSize: "2.5rem",
    position: "absolute",
    margin: theme.spacing(0, 1, 1, 1.5),
    top: '15px'
  },
  moveTopIcon: {
    backgroundColor: props => (props.moveTopClicked === true ?
        theme.palette.type === "dark" ? "#00e700" : "#00a500" :
        theme.palette.type === "dark" ? "#00b4ff" : "#00b4ff"
    ),
    '&:hover': {
      backgroundColor: props => (props.moveTopClicked === true ?
          theme.palette.type === "dark" ? "#00e700" : "#00a500" :
          theme.palette.type === "dark" ? "#00b4ff" : "#00b4ff"
      ),
    },
    '&:active': {
      backgroundColor: props => (props.moveTopClicked === true ?
          theme.palette.type === "dark" ? "#00e700" : "#00a500" :
          theme.palette.type === "dark" ? "#00b4ff" : "#00b4ff"
      ),
    },
    float: "left",
    position: "absolute",
    bottom: 0,
    margin: theme.spacing(0, 0, 1, 0),
    padding: theme.spacing(0, 0, 0, 0)
  },
  deleteIcon: {
    color: props =>
      props.deleteClicked === true
        ? "rgb(255, 85, 50)"
        : "rgb(194, 149, 63)",
    fontSize: "4rem"
  },
  questionWrapper: {},
  leftWrapper: {
    float: "left",
    textAlign: "left"
  },
  middleWrapper: {
    float: "left",
    width: "93%",
    textAlign: "left"
  },
  rightWrapper: {
    float: "right"
  },
  adminNote: {
    textAlign: "left",
    backgroundColor: theme.palette.type === "dark" ? "#8a3b3a" : "#ffc1c1",
    padding: theme.spacing(1, 1),
    margin: theme.spacing(0, 0, 2, 0)
  },
  messageSender: {
    textAlign: "left",
    fontSize: '14px',
    margin: theme.spacing(0, 0, 2, 0)
  },
  root: {
    padding: theme.spacing(1, 1),
    margin: theme.spacing(1, 0)
  },
  card: {
    // backgroundColor: theme.palette.type === "dark" ? "#8a3b3a" : "#ffc1c1",
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(1.5, 0)
  },
  centeredColumn: {
    minWidth: '70px',
    display: 'inline-flex',
    alignItems: 'center'
  },
  buttonsColumn: {
    // paddingRight: 0
    padding: theme.spacing(0, 0),
    margin: theme.spacing(0, 0),
    display: 'inline-grid'
  }
}));

const DragHandle = sortableHandle(() => (
  <DragHandleIcon style={{fontSize: "4rem", cursor: "row-resize"}}/>
));

const PresenterQuestion = ({card, sortIndex, onSortEnd, readonly}) => {
  const [clicked, setClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [moveTopClicked, setMoveTopClicked] = useState(false);
  const classes = useStyles({clicked, deleteClicked, moveTopClicked});
  const {api} = useContext(ApiContext);

  const handleClick = () => {
    if (clicked === false) {
      setClicked(true);
      setTimeout(() => setClicked(false), 2000);
    } else {
      api.connect().then(_ => {
        setClicked(false);
        api.sendToProjector(card);
      });
    }
  };

  const deleteQuestion = () => {
    if (deleteClicked === false) {
      setDeleteClicked(true);
      setTimeout(() => setDeleteClicked(false), 2000);
    } else {
      api.connect().then(_ => {
        setDeleteClicked(false);
        api.deletePresenterQuestion(card.id);
      });
    }
  };

  const moveItemTop = () => {
    if (moveTopClicked === false) {
      setMoveTopClicked(true);
      setTimeout(() => setMoveTopClicked(false), 2000);
    } else {
      setMoveTopClicked(false);
      onSortEnd({oldIndex: sortIndex, newIndex: 0});
    }
  };

  function MessageFrom({ card }) {
    if (card.from || card.company) {
      return (
        <Row>
          <Col>
            <Typography
              className={classes.messageSender}
              variant="h6"
              color="textPrimary"
              align="left"
            >
              Message from: {card.name} / {card.company}
            </Typography>
          </Col>
        </Row>
      );
    }
    return null;
  }

  return (
    <Card className={classes.card}>
      <Row style={{width: '100%'}}>
        <Col xs="auto" className={classes.centeredColumn}>
          {card.hot === 1 && <FavoriteIcon className={readonly ? classes.faveIconReadOnly : classes.faveIcon}/>}
          {!readonly && <><DragHandle/>
          <Button variant="contained" size="small"
                  className={classes.moveTopIcon} onClick={moveItemTop}>
            <MoveTopIcon/>
            TOP
          </Button></>}
        </Col>
        <Col>
          <Row>
            <Col>
              <Typography
                className={classes.question}
                variant="h4"
                color="textPrimary"
                align="left"
                gutterBottom
              >
                {card.text}
              </Typography>
            </Col>
          </Row>
          {card.field2 && (
            <Row>
              <Col>
                <Typography
                  className={classes.adminNote}
                  variant="h6"
                  color="textSecondary"
                  align="left"
                  gutterBottom
                >
                  {card.field2}
                </Typography>
              </Col>
            </Row>
          )}
          <MessageFrom card={card} />
        </Col>
        {!readonly && <Col xs="auto" className={classes.buttonsColumn}>
          <Row style={{alignSelf: 'start'}}>
            <IconButton
              className={classes.questionButtons}
              aria-label="CheckCircle"
              onClick={handleClick}
            >
              <CheckCircleIcon className={classes.checkIcon}/>
            </IconButton>
          </Row>
          <Row className={"align-bottom"} style={{alignSelf: 'end'}}>
            <IconButton className={classes.questionButtons}
                        aria-label="RemoveCircle" onClick={deleteQuestion}>
              <RemoveQuestionIcon className={classes.deleteIcon}/>
            </IconButton>
          </Row>
        </Col>}
      </Row>
    </Card>
  );
};
export default PresenterQuestion;
