import React, {useContext} from "react";
import PresenterQuestion from "./PresenterQuestion";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { Container } from "reactstrap";
import {ApiContext} from "../App";

export const QuestionListContext = React.createContext({
  clearClicks: false
});

const SortableItem = sortableElement(({ value, sortIndex, onSortEnd }) => {
  const {readonly} = useContext(ApiContext);
  return (
    <PresenterQuestion card={value} sortIndex={sortIndex} onSortEnd={onSortEnd} readonly={readonly}/>
  )
});

const QuestionList = ({ questions, onSortEnd }) => {
  const {readonly} = useContext(ApiContext)
  return (
    <Container>
      {questions.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} sortIndex={index} value={value} onSortEnd={onSortEnd} readonly={readonly} />
      ))}
    </Container>
  );
};
export default sortableContainer(QuestionList);
