import React, {useState, useContext, useEffect, useRef} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/AddBox";
import CheckCircleIconConfirm from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from '@material-ui/core/CircularProgress';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import "./admin.css";
import { Container } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fade from "@material-ui/core/Fade";
import Card from "@material-ui/core/Card";
import {ApiContext} from "../App";
import moment from "moment";

const CssTextField = withStyles({
  root: {
    '&.isReadOnly': {
      '& label.Mui-focused': {
        color: 'rgb(236, 131, 38)',
      },
    },

    '& .isReadOnly.MuiFilledInput-underline:after': {
      borderBottomColor: 'rgb(236, 131, 38)',
    },
  },
})(TextField);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  textField: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: "5px",
    width: "100%"
  },
  checkButton: {
    color: props => (props.clicked === true ?
      theme.palette.type === "dark" ? "#00e700" : "#00a500" :
      theme.palette.type === "dark" ? "#00b4ff" : "#00b4ff"
    )
  },
  checkIcon: {
    fontSize: "2.5rem"
  },
  deleteButton: {
    color: props => (props.deleteClicked === true ? "#ff3100" : "#ec8326"),
    fontSize: props => (props.deleteClicked === true ? "2rem" : "2.5rem"),
  },
  adminQuestion: {
    padding: "0",
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
    backgroundColor: props =>
      props.deleted === true
        ? "rgba(255,0,0,0.5)"
        : props.approved === true
        ? "rgba(0,255,0,0.5)"
        : "transparent"
  },
  deleteButtonProgress: {
    color: props => (props.deleteClicked === true ? "#ff3100" : "#ec8326"),
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
  checkButtonProgress: {
    color: props => (props.clicked === true ?
      theme.palette.type === "dark" ? "#00e700" : "#00a500" :
      theme.palette.type === "dark" ? "#00b4ff" : "#00b4ff"
    ),
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
  messageFrom: {
    textAlign: 'left'
  }
}));

const AdminQuestion = ({ updateQ, index, question }) => {
  const [values, setValues] = useState({
    id: question.id,
    text: question.text,
    field2: question.field2 || "",
    field3: question.field3 || "",
    hot: question.hot === 1,
    locked: question.locked
  });
  const [clicked, setClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [saved, setSaved] = useState(false);
  const [approving, setApproving] = useState(false);
  const [hiding, setHiding] = useState(false);
  const [editing, setEditing] = useState(false);
  const { api, setIsPolling } = useContext(ApiContext);

  let isMounted = useRef(true);

  useEffect(() => {
    return () => {
      // cancel timeouts
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    setValues({
      id: question.id,
      text: question.text,
      field2: question.field2 || "",
      field3: question.field3 || "",
      hot: question.hot === 1,
      locked: question.locked
    });
  }, [question]);

  const updateQuestion = (modified, hide) => {

    let hm = false;

    if (typeof hide !== 'undefined' && hide === true) {
      question.hidden = 1;
      hm = true;
    }

    if (typeof modified !== 'undefined' && modified === true) {
      question.approved = moment().format("YYYY-MM-DDTHH:mm:ssZ");
      hm = true;
    }

    if (
      question.text !== values.text ||
      question.field2 !== values.field2 ||
      question.field3 !== values.field3 ||
      question.hot !== values.hot ||
      hm
    ) {
      api.connect().then(_ => {
        api.updateQuestion(values);
        setSaved(true);
        updateQ(values);
        setTimeout(() => {
          if (isMounted.current) {
            setSaved(false);
          }
        }, 2000);
      });
    }
  };

  useEffect(() => {
    if (values.hot !== (question.hot===1)) {
      updateQuestion();
    }
  }, [values.hot]);

  const send = () => {
    if (clicked === false) {
      setClicked(true);
      setTimeout(() => {
        if (isMounted.current) setClicked(false);
      }, 2000);
    } else {
      setApproving(true);
      updateQuestion(true);
      api.connect().then(_ => {
        api.sendQuestionToPresenter(question.id, values, () => {
          if (isMounted.current) {
            setApproving(false)
          }
        });
      });
    }
  };

  const hide = () => {
    if (deleteClicked === false) {
      setDeleteClicked(true);
      setTimeout(() => {
        if (isMounted.current) setDeleteClicked(false)
      }, 2000);
    } else {
      setHiding(true);
      updateQuestion(undefined, true);
      api.connect().then(_ => {
        api.hideQuestion(question.id, () => {
          if (isMounted.current) setHiding(false);
        });
      });
    }
  };

  const handleChange = name => event => {
      setIsPolling(false);
      setValues({ ...values, [name]: event.target.value });
  };
  const handleSwitchChange = name => event => {
    setIsPolling(false);
    if (event.target.checked === true) {
      setValues({...values, [name]: 1});
    } else {
      setValues({...values, [name]: 0});
    }
  };

  const handleKeyDown = event => {
    // if (event.key === "Enter") {
    //   updateQuestion();
    // }
  };

  const handleOnBlur = event => {
    updateQuestion();
  };

  const handleOnFocus = event => {
    if(values.locked !== 1){
      setEditing(true);
      setIsPolling(false);
      api.connect().then(_ => {
        api.lockQuestion(question.id);
      });
    }
  };

  const isLocked = () => {
    return values.locked === 1 && !editing;
  };

  const classes = useStyles({
    clicked,
    deleteClicked,
    deleted: question.hidden === 1,
    approved: question.approved !== null
  });

  function MessageFrom ({ question }) {
    if (question.from || question.company) {
      return (
        <div className={classes.messageFrom}>Message from: {question.name} / {question.company}</div>
      )
    }
    return null
  }

  return (
    <Card className={classes.card}>
        <Container className={classes.adminQuestion}>
          <div className="wrapper">
            <aside className="aside aside-1">
              <IconButton
                className={classes.deleteButton}
                aria-label="RemoveCircle"
                onClick={hide}
                disabled={hiding}
              >
                <RemoveCircleIcon className={classes.deleteButton} />
                {hiding && <CircularProgress size={40} disableShrink className={classes.deleteButtonProgress} />}
              </IconButton>
            </aside>
            <div className={"middleSection"}>
              <FormControlLabel
                control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} checked={values.hot} value="hot" onChange={handleSwitchChange("hot")} />}
                label="Favorite"
              />
              <Fade in={saved}>
                <span>Saved!</span>
              </Fade>
              <article className="main">
                <CssTextField
                  id={`${values.id}-text`}
                  label="Question"
                  multiline
                  rowsMax="4"
                  value={values.text}
                  onChange={handleChange("text")}
                  className={classes.textField}
                  classes={{
                    root: isLocked() ? 'isReadOnly' : 'write',
                  }}
                  margin="normal"
                  variant="filled"
                  onKeyDown={handleKeyDown}
                  onBlur={handleOnBlur}
                  onFocus={handleOnFocus}
                  InputProps={{
                    readOnly: isLocked(),
                    classes:{
                      root: isLocked() ? 'isReadOnly' : 'write',
                    }
                  }}
                />
              </article>
              <div className={"middleSectionBottom"}>
                <div className="extrafield field2">
                  <CssTextField
                    id={`${values.id}-field2`}
                    label="Note for presenter"
                    className={classes.textField}
                    value={values.field2}
                    onChange={handleChange("field2")}
                    margin="normal"
                    variant="filled"
                    onKeyDown={handleKeyDown}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    classes={{
                      root: isLocked() ? 'isReadOnly' : 'write',
                    }}
                    InputProps={{
                      readOnly: isLocked(),
                      classes:{
                        root: isLocked() ? 'isReadOnly' : 'write',
                      }
                    }}
                  />
                </div>
                <div className="extrafield field3">
                  <CssTextField
                    id={`${values.id}-field3`}
                    label="Note for admin"
                    className={classes.textField}
                    value={values.field3}
                    onChange={handleChange("field3")}
                    margin="normal"
                    variant="filled"
                    onKeyDown={handleKeyDown}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    classes={{
                      root: isLocked() ? 'isReadOnly' : 'write',
                    }}
                    InputProps={{
                      readOnly: isLocked(),
                      classes:{
                        root: isLocked() ? 'isReadOnly' : 'write',
                      }
                    }}
                  />
                </div>
              </div>
              <MessageFrom question={question} />

            </div>
            <aside className="aside aside-2">
              <IconButton
                className={classes.checkButton}
                aria-label="CheckCircle"
                onClick={send}
                disabled={approving}
              >
                {approving ? <CheckCircleIconConfirm className={classes.checkIcon} /> : <CheckCircleIcon className={classes.checkIcon} />}
                {approving && <CircularProgress size={40} disableShrink className={classes.checkButtonProgress} />}
              </IconButton>
            </aside>
          </div>
        </Container>
    </Card>
  );
};
export default AdminQuestion;
