import io from "socket.io-client";
import axios from "axios";
import fileDownload from 'js-file-download';

var baseUrl = `${window.location.protocol}//${window.location.hostname}`;
if(!window.location.hostname.includes("vroom.telstraevents.com.au")){
  baseUrl = `${baseUrl}:8000`;
}

class Api {
  constructor(errorCb) {
    this.eventId = undefined;
    this.socket = io(`${baseUrl}`, {
      forceNew: true,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax : 5000,
      reconnectionAttempts: Infinity,
      transports: ['websocket'],
    });
    this.socket.on("vroomError", error => errorCb(error));
    // this.socket.on("error", error => errorCb(error));
    this.socket.on("error", error => {
      // this.socket.disconnect();
      // this.connected = false;
      // console.log("Disconnected on error");
      // this.socket.readyState = 'closed'; // Hack for https://github.com/Automattic/socket.io/issues/1860 .
      // this.socket.reconnect();
      // this.connect();
      //console.log("Error:", error);
    });
    this.socket.on('disconnect', () => {
      //console.log("Disconnected");
      this.connected = false;
      this.connect();
    });

    this.connected = false;
  }

  setEventId(eventId) {
    this.eventId = eventId;
  }

  setForceUpdateCB(forceUpdateCb) {
    this.socket.on('reconnect', () => {
      //console.log("Reconnected");
      if (typeof forceUpdateCb === 'function') {
        //console.log('Force update');
        forceUpdateCb();
      }
      // this.connect();
    });
    // this.eventId = eventId;
  }

  async connect() {
    let self = this;
    return new Promise((resolve, reject) => {
      if (self.connected === false) {
        this.socket.emit("room", this.eventId, () => {
          self.connected = true;
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  setupListener(listener, cb) {
    if(!this.socket.hasListeners(listener)) {
      console.log(`Setup ${listener} listener for ${this.eventId}`);
      this.socket.on(listener, message => cb(null, message));
    }
  }

  removeListener(listener) {
    this.socket.off(listener);
  }

  getAdminMessages(cb) {
    this.setupListener("adminMessages", cb);
    axios
      .get(`${baseUrl}/${this.eventId}/questions/getAdminMessages`)
      .catch(e => { console.log(e); });
  }

  getLastUpdated(cb) {
    this.setupListener("lastUpdated", cb);
    axios
      .get(`${baseUrl}/${this.eventId}/questions/getLastUpdated`)
      .catch(e => { console.log(e); });
  }

  getPresenterMessages(cb) {
    this.setupListener("presenterMessages", cb);
    axios
      .get(`${baseUrl}/${this.eventId}/questions/getPresenterMessages`)
      .then(response => cb(null, response.data));
  }

  getProjectorMessage(cb) {
    this.setupListener("projectorMessage", cb);
    axios
      .get(`${baseUrl}/${this.eventId}/questions/getProjectorMessage`)
      .then(response => cb(null, response.data));
  }

  sendToProjector(question) {
    axios
      .post(`${baseUrl}/${this.eventId}/questions/sendToProjector`, question)
      .catch(e => { console.log(e); });
      //.then(response => console.log(response));
  }

  deletePresenterQuestion(questionId) {
    axios
      .delete(`${baseUrl}/${this.eventId}/questions/deletePresenterQuestion/${questionId}`)
      .catch(e => { console.log(e); });
  }

  hideProjectorQuestion() {
    axios
      .delete(`${baseUrl}/${this.eventId}/questions/hideProjectorQuestion`)
      .catch(e => { console.log(e); });
  }

  loadNewQuestionsFromCOSI() {
    axios
      .get(`${baseUrl}/${this.eventId}/questions/pollCOSI`)
      .catch(e => { console.log(e); });
  }

  resetEvent() {
    axios
      .delete(`${baseUrl}/${this.eventId}/questions/resetEvent`)
      .catch(e => { console.log(e); });
  }

  sendQuestionToPresenter(questionId, values, cb) {
    axios
      .post(`${baseUrl}/${this.eventId}/questions/sendToPresenter`, {
        id: questionId,
        text: values.text,
        field2: values.field2,
        field3: values.field3,
        hot: values.hot
      })
      .then(response => {
        // console.log(response);
        cb();
      })
      .catch(error => {
        console.error(error);
        cb();
      });
  }

  hideQuestion(id, cb) {
    axios
      .delete(`${baseUrl}/${this.eventId}/questions/hideQuestion/${id}`)
      .then(response => {
        // console.log(response);
        cb();
      })
      .catch(error => {
        console.error(error);
        cb();
      });
  }

  reorderAllMessages(items) {
    axios
      .post(`${baseUrl}/${this.eventId}/questions/reorderAllMessages`, items)
      .catch(e => { console.log(e); });
  }

  getConfig(cb) {
    this.setupListener("config", cb);
    axios
      .get(`${baseUrl}/${this.eventId}/config`)
      .then(response => cb(null, response.data))
      .catch(error => cb(error, null));
  }

  toggleDefaultMessage(toggleValue) {
    axios
      .post(`${baseUrl}/${this.eventId}/config/toggleDefaultMessage`, {showDefaultMessage: toggleValue})
      .catch(e => { console.log(e); });
  }

  updateQuestion(question) {
    axios
      .put(`${baseUrl}/${this.eventId}/questions/updateQuestion`, {
        id: question.id,
        text: question.text,
        field2: question.field2,
        field3: question.field3,
        hot: question.hot
      })
      .catch(e => { console.log(e); });
  }

  insertQuestion(question, config) {
    axios
      .post(`${baseUrl}/${this.eventId}/questions/insertQuestion`, {
        text: question.text,
        field2: question.field2,
        field3: question.field3,
        hot: question.hot,
        configId: config.id
      })
      .catch(e => { console.log(e); });
  }

  exportData() {
    axios
      .post(`${baseUrl}/${this.eventId}/questions/report`)
      .then(response => {
        fileDownload(response.data, `${this.eventId}-report.csv`);
      })
      .catch(e => { console.log(e); });
  }

  lockQuestion(id) {
    this.socket.emit("lockQuestion", this.eventId, id, () => {
    });
    // axios
    //   .put(`${baseUrl}/${this.eventId}/questions/lockQuestion/${id}`)
    //   .then(response => {
    //     console.log(response);
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
  }

  loadCountdown(cb) {
    this.setupListener("countdown", cb);
    axios
      .get(`${baseUrl}/${this.eventId}/config/countdown`)
      .catch(e => { console.log(e); });
  }

  stopCountdown() {
    axios
      .put(`${baseUrl}/${this.eventId}/config/countdown/stop`)
      .catch(e => { console.log(e); });
  }

  startCountdown() {
    axios
      .put(`${baseUrl}/${this.eventId}/config/countdown/start`)
      .catch(e => { console.log(e); });
  }

  setCountdown(value) {
    axios
      .put(`${baseUrl}/${this.eventId}/config/countdown/set`, {
        value
      })
      .catch(e => { console.log(e); });
  }

}
export default Api;
