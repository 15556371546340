import React, {useContext, useEffect, useState} from "react";
import QuestionList from "./QuestionList";
import {Container, Row, Col} from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import RemoveQuestionIcon from "@material-ui/icons/Cancel";
import {withTheme, withStyles} from "@material-ui/styles";
import InvertColorsIcon from "@material-ui/icons/InvertColorsRounded";
import arrayMove from "array-move";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Countdown from "./Countdown";
import {ApiContext} from "../App";

const styles = {
  questionOnProjector: {
    backgroundColor: props => props.theme.palette.type === "dark" ? "#356199" : "#c3dced",
    padding: props => props.theme.spacing(2, 0, 0, 2),
    margin: props => props.theme.spacing(3, 0, 4, 0),
    minHeight: "60px"
  },
  adminNote: {
    textAlign: "left",
    backgroundColor: props => props.theme.palette.type === "dark" ? "#31446f" : "#e0eaff",
    padding: props => props.theme.spacing(1, 1),
  },
  headerCol: {
    alignItems: "center",
    verticalAlign: "middle",
    display: "inline-grid",
  }
};

//class PresenterView extends Component {
function PresenterView(props) {

  const {api, config, setConfig, readonly} = useContext(ApiContext);

  const [questions, setQuestions] = useState([])
  const [projectorQuestion, setProjectorQuestion] = useState({});
  const [deleteClicked, setDeleteClicked] = useState(false);
/*
  const [deleteClicked, setDeleteClicked] = useState({
    questions: [],
    projectorQuestion: null,
    deleteClicked: false,
  });
*/

/*  constructor(props) {
    super(props);

/!*
    this.api = new Api(
      props.match.params.eventId,
      error => this.displayError(),
      () => this.forceUpdate()
    );
*!/

    this.state = {
      questions: [],
      projectorQuestion: null,
      deleteClicked: false,
      invalidConfig: false,
      eventName: '...'
    };
    this.hideProjectorQuestion = this.hideProjectorQuestion.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);

    this.api.connect().then(_ => {
      this.api.getProjectorMessage((err, question) => {
        this.setState({
          projectorQuestion: question
        });
      });

      this.api.getConfig((err, config) => {
        this.setState({
          eventName: config.eventName
        });
      });

    });

  }*/

  const getConfig = () => {
    api.connect().then(_ => {
      api.getConfig((err, config) => {
        setConfig(config);
      });
    })
  }

/*  const registerListRef = listInstance => {
    this.List = listInstance;
  };*/

/*  const displayError = (error = "") => {
    console.log(error);
    this.setState({invalidConfig: true});
  }*/

  const forceUpdate = () => {
    api.connect().then(_ => {
      api.getPresenterMessages((err, questions) => {
        setQuestions(questions);
      });
    });
  }

  useEffect(() => {

    api.setForceUpdateCB(forceUpdate);

    getConfig();
    setTimeout(getConfig, 5000);

    api.getProjectorMessage((err, question) => {
      setProjectorQuestion(question);
    });

    api.connect().then(_ => {
      api.getPresenterMessages((err, questions) => {
        setQuestions(questions);
      });
    });
  }, [api, setProjectorQuestion, setQuestions]);

  useEffect(() => {
    if (deleteClicked === true) {
      api.connect().then(_ => {
        api.hideProjectorQuestion();
      });
    }
  },[deleteClicked]);

  const hideProjectorQuestion = ()  => {
    if (deleteClicked === false) {
      setDeleteClicked(true)
      //this.setState({...this.state, deleteClicked: true});
      setTimeout(
        () => setDeleteClicked(false),
        2000
      );
    }
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    if (oldIndex !== newIndex) {
      let newlySortedQuestions = arrayMove(questions, oldIndex, newIndex);
      setQuestions(newlySortedQuestions);
      api.connect().then(_ => {
        api.reorderAllMessages(newlySortedQuestions);
      });
    }
  }

    // if (this.state.invalidConfig) return <ErrorView configNotFound={true}/>;
  //render() {
    return (
      <div className="App-intro">
        <Container>
          <AppBar position="static" color="default"
                  style={{marginBottom: '15px'}}>
            <Toolbar>
              <Row style={{width: '100%'}}>
                <Col className={props.classes.headerCol}>
                  <Typography variant="h5" color="inherit" align="left">
                    {config.eventName}
                  </Typography>
                </Col>
                <Col className={props.classes.headerCol}>
                  <Typography color="inherit" variant="h6">
                    Timer
                  </Typography>
                  <Typography color="inherit" variant="subtitle2">
                    <Countdown showControls={false}/>
                  </Typography>
                </Col>

                <Col xs="auto">
                  <IconButton
                    aria-label="InvertColors"
                    onClick={props.onThemeChange}
                  >
                    <InvertColorsIcon/>
                  </IconButton>
                </Col>
              </Row>
            </Toolbar>
          </AppBar>

          <Card className={props.classes.questionOnProjector}>
            {projectorQuestion && (
              <CardActions disableSpacing>
                <Container>
                  <Row>
                    <Col>
                      <Typography variant="h3" color="textPrimary" align="left"
                                  gutterBottom>
                        {projectorQuestion.text}
                      </Typography>
                    </Col>
                  </Row>
                  {projectorQuestion.field2 && (
                    <Row>
                      <Col>
                        <Typography className={props.classes.adminNote}
                                    variant="h6" color="textSecondary"
                                    align="left" gutterBottom>
                          {projectorQuestion.field2}
                        </Typography>
                      </Col>
                    </Row>
                  )}
                </Container>
                {!readonly &&
                <IconButton
                  aria-label="RemoveCircle"
                  onClick={hideProjectorQuestion}
                >
                  <RemoveQuestionIcon
                    style={{
                      color: deleteClicked ? "rgb(255, 85, 50)" : "rgb(194, 149, 63)",
                      fontSize: "4rem"
                    }}
                  />
                </IconButton>}
              </CardActions>
            )}
          </Card>
          <QuestionList
            questions={questions}
            onSortEnd={onSortEnd}
            useDragHandle
            lockAxis={"y"}
            // getRef={this.registerListRef}
          />
        </Container>
      </div>
    );
  //}
}

export default withTheme(withStyles(styles)(PresenterView));
