import React, { useState, useEffect, useRef, useContext } from "react";
import "./Countdown.css";

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {ApiContext} from "../App";

class Clock extends React.Component {
  render () {
    const {countdown} = this.props;
    var className = "displayedTime";
    if (countdown.startsWith('-')) {
      className += ' warning';
    }
    return (
      <div className={className}>
        <h1>{countdown}</h1>
      </div>
    )
  }
}

const Countdown = ({
  showControls
}) => {
  const secondsRef = useRef(null);
  const [countdown, setCountdown] = useState('');
  const { api } = useContext(ApiContext);

  useEffect(() => {
    api.connect().then(_ => {
      api.loadCountdown((err, countdown) => {
        setCountdown(countdown);
      });
    });
  }, []);

  const handleStart = () => {
    api.connect().then(_ => {
      api.startCountdown();
    });
  }

  const handleStop = () => {
    api.connect().then(_ => {
      api.stopCountdown();
    });
  }

  const onSubmit = () => {
    let strSeconds = secondsRef.current.value ? secondsRef.current.value : 300;
    secondsRef.current.value = '';
    api.connect().then(_ => {
      api.setCountdown(parseInt(strSeconds, 10));
    });
  }

  return (
    <div className="countdown">
      <Clock countdown={countdown}/>
      {showControls && (
      <div className="buttons">
        <input type="text" ref={secondsRef} placeholder="Secs" size={4} maxlengh={4}/>
        <ButtonGroup size="small" variant="text" color="default" aria-label="small text primary button group">
          <Button onClick={onSubmit}>Set</Button>
          <Button onClick={handleStart}>Start</Button>
          <Button onClick={handleStop}>Stop</Button>
        </ButtonGroup>
      </div>
      )}
    </div>
  )
}

export default Countdown;
