import React, {useContext, useEffect, useState} from "react";
import Fade from "@material-ui/core/Fade";
import {Textfit} from "react-textfit";
import "./ProjectorView.css";
import {ApiContext} from "../App";
import _ from "lodash";

function ProjectorView() {

  const {api, config, setConfig } = useContext(ApiContext);
  const [divStyles, setDivStyles] = useState({});
  const [fontSize, setFontSize] = useState({});
  const [textFitStyles, setTextFitStyles] = useState({});

  const [visible, setVisible] = useState(false);
  const [question, setQuestion] = useState('');

  const globalTimeout = 1000;

  const configValidator = (config) => {
    let errors = [];

    if (!config.minFontSize) {
      errors.push("No minFontSize set.");
    }
    if (!config.maxFontSize) {
      errors.push("No maxFontSize set.");
    }

    if (!config.textarea) {
      errors.push("No textarea set.");
    } else {
      let textAreaArray = config.textarea.split(",");

      if (textAreaArray.length !== 4) {
        errors.push("No textarea set.");
      } else {
        let textArea = {
          top: textAreaArray[0],
          left: textAreaArray[1],
          width: textAreaArray[2],
          height: textAreaArray[3]
        };
        config.textarea = textArea;
      }
    }

    if (errors.length > 0) {
      //this.displayError();
      console.log({Errors: errors});
      return {};
      // throw new Error(errors);
    }

    setDivStyles({
      color: config.fontColour,
      fontStyle: config.fontStyle,
      fontWeight: config.fontWeight,
      top: config.textarea.top,
      left: config.textarea.left,
      textAlign: config.textAlign,
      transform: config.transform
    });

    setTextFitStyles({
      width: config.textarea.width,
      height: config.textarea.height
    });

    setFontSize({
      min: parseInt(config.minFontSize),
      max: parseInt(config.maxFontSize)
    });

    return config;
  }

  const getConfig = () => {
    api.connect().then(_ => {
      api.getConfig((err, config) => {
        setConfig(configValidator(config));
      });
    })
  }

  useEffect(() => {
    if (!_.isEmpty(config)) {
      if (config.background) {
        document.body.style.background = `url(${config.background})`;
        document.body.style.backgroundColor = `#ffffff`;
        document.body.style.color = `#000000`;
        document.body.style.backgroundAttachment = `fixed`;
        document.body.style.backgroundSize = `cover`;
        document.body.style.backgroundPosition = `center center`;
      }
    }
  }, [config]);

  const getQuestion = () => {
    api.connect().then(_ => {
      api.getProjectorMessage((err, q) => {
        setVisible(false);
        let message = "";
        if (q !== null) {
          message = q.text;
        } else if (config.showDefaultMessage && config.defaultMessage) {
          message = config.defaultMessage;
        }
        setTimeout(() => {
          setQuestion(message);
          setVisible(true);
        }, globalTimeout);
      });
    });
  }

  useEffect(() => {
    getConfig();
    getQuestion();
  }, []);

  return (
    <div>
      {_.isEmpty(config)
        ? <span />
        : (<div className="App-intro textfill" style={divStyles}>
          <Fade in={visible} timeout={globalTimeout}>
            <Textfit
              mode="multi"
              min={fontSize.min}
              max={fontSize.max}
              style={textFitStyles}
            >
              {question}
            </Textfit>
          </Fade>
        </div>)
      }
    </div>
  );
}

export default ProjectorView;
