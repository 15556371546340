import React from 'react'

const Pause = ({onPollingClick}) => {
  return (
    <svg style={{fill:"white", cursor:"hand"}} viewBox="0 0 20 20" onClick={onPollingClick}>
      <polygon points="0,0 2,0 2,20 0,20" />
      <polygon points="5,0 7,0 7,20 5,20" />
    </svg>
  )
}

export default Pause
