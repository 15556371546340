import React from "react";
import { Container } from "reactstrap";
import { withTheme } from "@material-ui/styles";

const ErrorView = ({ configNotFound }) => {
  return (
    <Container>
      {configNotFound ? "Config not found" : "Page not found"}
    </Container>
  );
};
export default withTheme(ErrorView);
